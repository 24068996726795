define("ember-contextual-table/templates/data-paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gE5nhTFK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"data\",\"currentPage\",\"next\",\"previous\",\"first\",\"last\",\"pagesTotal\",\"hasNext\",\"hasPrevious\"],[[24,[\"paginatedData\"]],[24,[\"currentPage\"]],[28,\"action\",[[23,0,[]],\"next\"],null],[28,\"action\",[[23,0,[]],\"previous\"],null],[28,\"action\",[[23,0,[]],\"first\"],null],[28,\"action\",[[23,0,[]],\"last\"],null],[24,[\"pagesTotal\"]],[24,[\"hasNext\"]],[24,[\"hasPrevious\"]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/data-paginator.hbs"
    }
  });

  _exports.default = _default;
});