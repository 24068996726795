define("ember-bootstrap/templates/components/bs-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k98G7+Gu",
    "block": "{\"symbols\":[\"Dialog\",\"@size\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[4,\"if\",[[23,0,[\"inDom\"]]],null,{\"statements\":[[5,\"maybe-in-element\",[],[[\"@destinationElement\",\"@renderInPlace\"],[[23,0,[\"destinationElement\"]],[23,0,[\"_renderInPlace\"]]]],{\"statements\":[[4,\"let\",[[28,\"component\",[[23,0,[\"dialogComponent\"]]],null]],null,{\"statements\":[[0,\"      \"],[6,[23,1,[]],[[13,4]],[[\"@onClose\",\"@fade\",\"@showModal\",\"@keyboard\",\"@size\",\"@backdropClose\",\"@inDom\",\"@paddingLeft\",\"@paddingRight\",\"@centered\",\"@class\",\"@id\",\"@scrollable\"],[[23,0,[\"close\"]],[23,0,[\"fade\"]],[23,0,[\"showModal\"]],[23,0,[\"keyboard\"]],[23,2,[]],[23,0,[\"backdropClose\"]],[23,0,[\"inDom\"]],[23,0,[\"paddingLeft\"]],[23,0,[\"paddingRight\"]],[28,\"bs-eq\",[[23,0,[\"position\"]],\"center\"],null],[23,3,[]],[23,0,[\"modalId\"]],[23,0,[\"scrollable\"]]]],{\"statements\":[[0,\"\\n        \"],[14,5,[[28,\"hash\",null,[[\"header\",\"body\",\"footer\",\"close\",\"submit\"],[[28,\"component\",[[23,0,[\"headerComponent\"]]],[[\"onClose\"],[[23,0,[\"close\"]]]]],[28,\"component\",[[23,0,[\"bodyComponent\"]]],null],[28,\"component\",[[23,0,[\"footerComponent\"]]],[[\"onClose\",\"onSubmit\"],[[23,0,[\"close\"]],[23,0,[\"doSubmit\"]]]]],[23,0,[\"close\"]],[23,0,[\"doSubmit\"]]]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showBackdrop\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-backdrop \",[28,\"if\",[[23,0,[\"fade\"]],\"fade\"],null],\" \",[28,\"if\",[[23,0,[\"showModal\"]],[23,0,[\"showClass\"]]],null]]]],[11,\"id\",[23,0,[\"backdropId\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal.hbs"
    }
  });

  _exports.default = _default;
});