define("ember-bootstrap/components/bs-collapse", ["exports", "ember-bootstrap/components/base/bs-collapse", "@ember-decorators/component"], function (_exports, _bsCollapse, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Collapse = (_dec = (0, _component.classNameBindings)('showContent:in'), _dec(_class = class Collapse extends _bsCollapse.default {}) || _class);
  _exports.default = Collapse;
});