define("ember-simple-auth-token/authenticators/token", ["exports", "fetch", "ember-simple-auth/authenticators/base"], function (_exports, _fetch, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Authenticator that works with token-based authentication like JWT.
  
    _The factory for this authenticator is registered as `'authenticator:token'` in Ember's container._
  
    @class Token
    @namespace SimpleAuth.Authenticators
    @module ember-simple-auth-token/authenticators/token
    @extends Base
  */
  var _default = _base.default.extend({
    /**
      @method init
    */
    init() {
      this._super(...arguments);

      const owner = Ember.getOwner(this);
      const environment = owner ? owner.resolveRegistration('config:environment') || {} : {};
      const config = environment['ember-simple-auth-token'] || {};
      this.serverTokenEndpoint = config.serverTokenEndpoint || '/api/token-auth/';
      this.tokenPropertyName = config.tokenPropertyName || 'token';
      this.headers = config.headers || {};
    },

    /**
      Restores the session from a set of session properties; __will return a resolving promise when there's a non-empty `token` in the `properties`__ and a rejecting promise otherwise.
       @method restore
      @param {Object} properties Properties to restore the session from
      @return {Promise} Promise that when it resolves results in the session being authenticated
    */
    restore(properties) {
      const propertiesObject = Ember.Object.create(properties);
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(propertiesObject.get(this.tokenPropertyName))) {
          return resolve(properties);
        } else {
          return reject();
        }
      });
    },

    /**
      Authenticates the session with the specified `credentials`; the credentials are `POST`ed to the [`Authenticators.Token#serverTokenEndpoint`](#SimpleAuth-Authenticators-Token-serverTokenEndpoint) and if they are valid the server returns an auth token in response. __If the credentials are valid and authentication succeeds, a promise that resolves with the server's response is returned__, otherwise a promise that rejects with the server error is returned.
       @method authenticate
      @param {Object} credentials Credentials to authenticate the session with
      @param {Object} headers Headers to send with the authentication request
      @return {Promise} Promise that resolves when an auth token is successfully acquired from the server and rejects otherwise
    */
    authenticate(credentials, headers) {
      return this.makeRequest(this.serverTokenEndpoint, credentials, Ember.assign({}, this.headers, headers)).then(response => {
        return response.json;
      });
    },

    /**
      Does nothing
       @method invalidate
      @return {Promise} Resolving promise
    */
    invalidate() {
      return Ember.RSVP.resolve();
    },

    /**
      @method makeRequest
      @param {String} url Server endpoint
      @param {Object} data Object that will be sent to server
      @param {Object} headers Additional headers that will be sent to server
      @return {Promise} Promise that resolves when the request is successfully and rejects otherwise
    */
    makeRequest(url, data, headers) {
      return (0, _fetch.default)(url, {
        method: 'POST',
        headers: Ember.assign({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }, headers),
        body: JSON.stringify(data)
      }).then(response => {
        const res = {
          statusText: response.statusText,
          status: response.status,
          headers: response.headers
        };
        return response.text().then(text => {
          res.text = text;

          try {
            res.json = JSON.parse(text);
          } catch (e) {
            return Ember.RSVP.reject(res);
          }

          if (response.ok) {
            return res;
          } else {
            return Ember.RSVP.reject(res);
          }
        }).catch(() => {
          return Ember.RSVP.reject(res);
        });
      });
    }

  });

  _exports.default = _default;
});