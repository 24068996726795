define("ember-bootstrap/utils/default-decorator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = defaultValue;

  function defaultValue(target, key, descriptor) {
    let {
      initializer,
      value
    } = descriptor;
    return Ember.computed({
      get() {
        return initializer ? initializer.call(this) : value;
      },

      set(_, v) {
        return v;
      }

    })(target, key, { ...descriptor,
      value: undefined,
      initializer: undefined
    });
  }
});