define("ember-bootstrap/components/base/bs-modal/header", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/header", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _header, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ModalHeader = (
  /**
  
   Modal header element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalHeader
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component.layout)(_header.default), _dec2 = (0, _component.classNames)('modal-header'), _dec(_class = _dec2(_class = (_class2 = class ModalHeader extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "closeButton", _descriptor, this);

      _initializerDefineProperty(this, "titleComponent", _descriptor2, this);

      _initializerDefineProperty(this, "closeComponent", _descriptor3, this);
    }
    /**
     * @event onClose
     * @public
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "closeButton", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "titleComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/header/title';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "closeComponent", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bs-modal/header/close';
    }
  })), _class2)) || _class) || _class);
  _exports.default = ModalHeader;
});