define("ember-contextual-table/components/dt-filterable-column-footer", ["exports", "ember-contextual-table/mixins/filterable-column"], function (_exports, _filterableColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_filterableColumn.default, {
    tagName: 'td',
    footer: true,
    classNames: ['contextual-filterable-footer-cell']
  });

  _exports.default = _default;
});