define("ember-bootstrap/templates/components/bs-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mARqlCo6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[28,\"if\",[[23,0,[\"fluid\"]],\"container-fluid\",\"container\"],null]],[8],[0,\"\\n  \"],[14,1,[[28,\"hash\",null,[[\"toggle\",\"content\",\"nav\",\"collapse\",\"expand\",\"toggleNavbar\"],[[28,\"component\",[[23,0,[\"toggleComponent\"]]],[[\"onClick\",\"collapsed\"],[[23,0,[\"toggleNavbar\"]],[24,[\"_collapsed\"]]]]],[28,\"component\",[[23,0,[\"contentComponent\"]]],[[\"collapsed\",\"onHidden\",\"onShown\"],[[24,[\"_collapsed\"]],[24,[\"onCollapsed\"]],[24,[\"onExpanded\"]]]]],[28,\"component\",[[23,0,[\"navComponent\"]]],[[\"linkToComponent\"],[[28,\"component\",[[23,0,[\"linkToComponent\"]]],[[\"onCollapse\"],[[23,0,[\"collapse\"]]]]]]]],[23,0,[\"collapse\"]],[23,0,[\"expand\"]],[23,0,[\"toggleNavbar\"]]]]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-navbar.hbs"
    }
  });

  _exports.default = _default;
});