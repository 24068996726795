define("ember-bootstrap/templates/components/bs-form/element/feedback-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4KCLfVAy",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,0,[\"show\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"form-control-feedback \",[23,0,[\"iconName\"]]]]],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/feedback-icon.hbs"
    }
  });

  _exports.default = _default;
});