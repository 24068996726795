define("ember-contextual-table/components/dt-filter-and-sortable-column-footer", ["exports", "ember-contextual-table/mixins/filter-and-sortable-column"], function (_exports, _filterAndSortableColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_filterAndSortableColumn.default, {
    tagName: 'td',
    footer: true,
    classNames: ['contextual-filter-and-sortable-footer-cell']
  });

  _exports.default = _default;
});