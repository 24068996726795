define("ember-popper/templates/components/ember-popper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2wlA9bWB",
    "block": "{\"symbols\":[\"@id\",\"@class\",\"@ariaRole\",\"&attrs\",\"&default\"],\"statements\":[[5,\"maybe-in-element\",[],[[\"@destinationElement\",\"@renderInPlace\"],[[23,0,[\"_popperContainer\"]],[23,0,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"id\",[23,1,[]]],[12,\"class\",[23,2,[]]],[12,\"hidden\",[23,0,[\"hidden\"]]],[12,\"role\",[23,3,[]]],[13,4],[3,\"ref\",[[23,0,[]],\"_popperElement\"]],[8],[0,\"\\n    \"],[14,5,[[28,\"hash\",null,[[\"disableEventListeners\",\"enableEventListeners\",\"scheduleUpdate\",\"update\"],[[28,\"action\",[[23,0,[]],\"disableEventListeners\"],null],[28,\"action\",[[23,0,[]],\"enableEventListeners\"],null],[28,\"action\",[[23,0,[]],\"scheduleUpdate\"],null],[28,\"action\",[[23,0,[]],\"update\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-popper/templates/components/ember-popper.hbs"
    }
  });

  _exports.default = _default;
});