define("ember-contextual-table/components/dt-selection-column-header", ["exports", "ember-contextual-table/templates/dt-selection-column-header"], function (_exports, _dtSelectionColumnHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dtSelectionColumnHeader.default,
    tagName: 'th',
    classNames: ['contextual-selection-header-cell'],
    actions: {
      change: function (checked) {
        let actionName = checked ? 'selected' : 'deselected';

        let action = this.get(actionName) || function () {};

        action();
      }
    }
  });

  _exports.default = _default;
});