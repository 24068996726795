define('ember-leaflet-contextmenu/components/leaflet-contextmenu', ['exports', 'ember-leaflet/components/base-layer', 'ember-leaflet/components/leaflet-map', 'ember-composability-tools'], function (exports, _baseLayer, _leafletMap, _emberComposabilityTools) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const assert = Ember.assert,
        observer = Ember.observer;
  exports.default = _baseLayer.default.extend(_emberComposabilityTools.ParentMixin, {
    addToContainer() {
      let parentComponent = this.get('parentComponent');
      assert('contextmenu is currently only supported on leaflet-map not on any layer', parentComponent instanceof _leafletMap.default);

      // store reference to map which is used by leaflet-contextmenu-item
      this._map = parentComponent._layer;

      this.setDisabled();
    },

    createLayer() {
      // nothing to do here
    },

    /**
     * If `true` contextmenu won't show up on rightclick.
     *
     * @param disabled
     * @type boolean
     * @default true
     * @public
     */
    disabled: false,
    setDisabled() {
      let parentComponent = this.get('parentComponent');
      let disabled = this.get('disabled');
      if (disabled) {
        parentComponent._layer.contextmenu.disable();
      } else {
        parentComponent._layer.contextmenu.enable();
      }
    },
    onDisabledChange: observer('disabled', function () {
      this.setDisabled();
    })
  });
});