define("ember-bootstrap/components/bs-tooltip/element", ["exports", "ember-bootstrap/components/base/bs-tooltip/element", "ember-bootstrap/utils/default-decorator"], function (_exports, _element, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TooltipElement = (_dec = Ember.computed('fade', 'actualPlacement', 'showHelp'), (_class = class TooltipElement extends _element.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "arrowClass", _descriptor, this);
    }

    get popperClassNames() {
      let classes = ['tooltip', 'ember-bootstrap-tooltip', this.get('actualPlacement')];

      if (this.get('fade')) {
        classes.push('fade');
      }

      if (this.get('showHelp')) {
        classes.push('in');
      }

      return classes;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "arrowClass", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tooltip-arrow';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "popperClassNames", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "popperClassNames"), _class.prototype)), _class));
  _exports.default = TooltipElement;
});