define("ember-file-upload/computed/sum-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(collectionKey, itemKey) {
    return Ember.computed(`${collectionKey}.@each.${itemKey}`, function () {
      let collection = Ember.get(this, collectionKey);
      return collection.reduce(function (sum, item) {
        return sum + Ember.get(item, itemKey);
      }, 0);
    });
  }
});