define("ember-awesome-macros/hash", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deconstructArgs = deconstructArgs;
  _exports.default = _default;
  _exports.reduceValues = reduceValues;

  function deconstructArgs(args) {
    let hashKeys = [];
    let hashValues = [];
    args.forEach(arg => {
      if (typeof arg === 'string') {
        hashKeys.push(arg);
        hashValues.push(arg);
      } else {
        let props = Object.getOwnPropertyNames(arg);
        hashKeys = hashKeys.concat(props);
        hashValues = hashValues.concat(props.map(prop => arg[prop]));
      }
    });
    return {
      hashKeys,
      hashValues
    };
  }

  function reduceValues(hashKeys, newValues) {
    return newValues.reduce((newHash, val, i) => {
      newHash[hashKeys[i]] = val;
      return newHash;
    }, {});
  }

  function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    let {
      hashKeys,
      hashValues
    } = deconstructArgs(args);
    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, newValues = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        newValues[_key2] = arguments[_key2];
      }

      let newHash = reduceValues(hashKeys, newValues);
      return Ember.Object.create(newHash);
    })(...hashValues);
  }
});