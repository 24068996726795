define("ember-contextual-table/mixins/sortable-column", ["exports", "ember-contextual-table/templates/dt-sortable-column"], function (_exports, _dtSortableColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    layout: _dtSortableColumn.default,
    upArrowVisible: Ember.computed('isAscending', function () {
      return this.get('isAscending') === false;
    }),
    downArrowVisible: Ember.computed('isAscending', function () {
      return this.get('isAscending') === true;
    }),

    fireSortInformationUpdatedEvent(isAscending) {
      let actionHandler = this.get('sortinformationupdated') || function () {};

      actionHandler(this.get('propertyName'), isAscending);
    },

    actions: {
      onsortinfoupdate: function (isAscending) {
        this.fireSortInformationUpdatedEvent(isAscending);
      },
      onclick: function () {
        if (this.get('isAscending') === true) {
          this.set('isAscending', false);
        } else if (this.get('isAscending') === undefined) {
          this.set('isAscending', true);
        } else {
          this.set('isAscending', undefined);
        }

        this.fireSortInformationUpdatedEvent(this.get('isAscending'));
      }
    }
  });

  _exports.default = _default;
});