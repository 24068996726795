define("ember-basic-dropdown/components/basic-dropdown", ["exports", "@glimmer/component", "ember-basic-dropdown/utils/calculate-position", "require"], function (_exports, _component, _calculatePosition, _require) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const UNINITIALIZED = {};
  const IGNORED_STYLES = ['top', 'left', 'right', 'width', 'height'];
  let BasicDropdown = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class BasicDropdown extends _component.default {
    get horizontalPosition() {
      return this.args.horizontalPosition || 'auto'; // auto-right | right | center | left
    }

    get verticalPosition() {
      return this.args.verticalPosition || 'auto'; // above | below
    }

    get destination() {
      return this.args.destination || this._getDestinationId();
    }

    get disabled() {
      let newVal = this.args.disabled || false;

      if (this._previousDisabled !== UNINITIALIZED && this._previousDisabled !== newVal) {
        Ember.run.schedule('actions', () => {
          if (newVal && this.publicAPI.isOpen) {
            this.isOpen = false;
          }

          this.args.registerAPI && this.args.registerAPI(this.publicAPI);
        });
      }

      this._previousDisabled = newVal;
      return newVal;
    }

    get publicAPI() {
      return {
        uniqueId: this._uid,
        isOpen: this.isOpen,
        disabled: this.disabled,
        actions: this._actions
      };
    } // Lifecycle hooks


    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "hPosition", _descriptor, this);

      _initializerDefineProperty(this, "vPosition", _descriptor2, this);

      _initializerDefineProperty(this, "top", _descriptor3, this);

      _initializerDefineProperty(this, "left", _descriptor4, this);

      _initializerDefineProperty(this, "right", _descriptor5, this);

      _initializerDefineProperty(this, "width", _descriptor6, this);

      _initializerDefineProperty(this, "height", _descriptor7, this);

      _initializerDefineProperty(this, "otherStyles", _descriptor8, this);

      _initializerDefineProperty(this, "isOpen", _descriptor9, this);

      _initializerDefineProperty(this, "renderInPlace", _descriptor10, this);

      _defineProperty(this, "previousVerticalPosition", void 0);

      _defineProperty(this, "previousHorizontalPosition", void 0);

      _defineProperty(this, "destinationElement", void 0);

      _defineProperty(this, "_uid", Ember.guidFor(this));

      _defineProperty(this, "_dropdownId", this.args.dropdownId || `ember-basic-dropdown-content-${this._uid}`);

      _defineProperty(this, "_previousDisabled", UNINITIALIZED);

      _defineProperty(this, "_actions", {
        open: this.open,
        close: this.close,
        toggle: this.toggle,
        reposition: this.reposition
      });

      if (this.args.onInit) {
        this.args.onInit(this.publicAPI);
      }

      this.args.registerAPI && this.args.registerAPI(this.publicAPI);
    }

    willDestroy() {
      super.willDestroy();

      if (this.args.registerAPI) {
        this.args.registerAPI(null);
      }
    } // Actions


    open(e) {
      if (this.isDestroyed) {
        return;
      }

      if (this.publicAPI.disabled || this.publicAPI.isOpen) {
        return;
      }

      if (this.args.onOpen && this.args.onOpen(this.publicAPI, e) === false) {
        return;
      }

      this.isOpen = true;
      this.args.registerAPI && this.args.registerAPI(this.publicAPI);
    }

    close(e, skipFocus) {
      if (this.isDestroyed) {
        return;
      }

      if (this.publicAPI.disabled || !this.publicAPI.isOpen) {
        return;
      }

      if (this.args.onClose && this.args.onClose(this.publicAPI, e) === false) {
        return;
      }

      if (this.isDestroyed) {
        return; // To check that the `onClose` didn't destroy the dropdown
      }

      this.hPosition = this.vPosition = null;
      this.top = this.left = this.right = this.width = this.height = undefined;
      this.previousVerticalPosition = this.previousHorizontalPosition = undefined;
      this.isOpen = false;
      this.args.registerAPI && this.args.registerAPI(this.publicAPI);

      if (skipFocus) {
        return;
      }

      let trigger = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);

      if (trigger && trigger.tabIndex > -1) {
        trigger.focus();
      }
    }

    toggle(e) {
      if (this.publicAPI.isOpen) {
        this.close(e);
      } else {
        this.open(e);
      }
    }

    reposition() {
      if (!this.publicAPI.isOpen) {
        return;
      }

      let dropdownElement = document.getElementById(this._dropdownId);
      let triggerElement = document.querySelector(`[data-ebd-id=${this.publicAPI.uniqueId}-trigger]`);

      if (!dropdownElement || !triggerElement) {
        return;
      }

      this.destinationElement = this.destinationElement || document.getElementById(this.destination);
      let {
        horizontalPosition,
        verticalPosition,
        previousHorizontalPosition,
        previousVerticalPosition
      } = this;
      let {
        renderInPlace = false,
        matchTriggerWidth = false
      } = this.args;
      let calculatePositionFn = this.args.calculatePosition || _calculatePosition.default;
      let positionData = calculatePositionFn(triggerElement, dropdownElement, this.destinationElement, {
        horizontalPosition,
        verticalPosition,
        previousHorizontalPosition,
        previousVerticalPosition,
        renderInPlace,
        matchTriggerWidth,
        dropdown: this
      });
      return this.applyReposition(triggerElement, dropdownElement, positionData);
    }

    applyReposition(_trigger, dropdown, positions) {
      let changes = {
        hPosition: positions.horizontalPosition,
        vPosition: positions.verticalPosition,
        otherStyles: Ember.assign({}, this.otherStyles)
      };

      if (positions.style) {
        if (positions.style.top !== undefined) {
          changes.top = `${positions.style.top}px`;
        } // The component can be aligned from the right or from the left, but not from both.


        if (positions.style.left !== undefined) {
          changes.left = `${positions.style.left}px`;
          changes.right = undefined; // Since we set the first run manually we may need to unset the `right` property.

          if (positions.style.right !== undefined) {
            positions.style.right = undefined;
          }
        } else if (positions.style.right !== undefined) {
          changes.right = `${positions.style.right}px`;
          changes.left = undefined;
        }

        if (positions.style.width !== undefined) {
          changes.width = `${positions.style.width}px`;
        }

        if (positions.style.height !== undefined) {
          changes.height = `${positions.style.height}px`;
        }

        if (this.top === undefined) {
          // Bypass Ember on the first reposition only to avoid flickering.
          let cssRules = [];

          for (let prop in positions.style) {
            if (positions.style[prop] !== undefined) {
              if (typeof positions.style[prop] === 'number') {
                cssRules.push(`${prop}: ${positions.style[prop]}px`);
              } else {
                cssRules.push(`${prop}: ${positions.style[prop]}`);
              }
            }
          }

          dropdown.setAttribute('style', cssRules.join(';'));
        }
      }

      for (let prop in positions.style) {
        // Array.includes is not available for IE11
        if (IGNORED_STYLES.indexOf(prop) === -1) {
          changes.otherStyles;
          changes.otherStyles[prop] = positions.style[prop];
        }
      }

      this.hPosition = changes.hPosition;
      this.vPosition = changes.vPosition;
      this.top = changes.top;
      this.left = changes.left;
      this.right = changes.right;
      this.width = changes.width;
      this.height = changes.height;
      this.otherStyles = changes.otherStyles;
      this.previousHorizontalPosition = positions.horizontalPosition;
      this.previousVerticalPosition = positions.verticalPosition;
      return changes;
    }

    _getDestinationId() {
      let config = Ember.getOwner(this).resolveRegistration('config:environment');
      let id;

      if (config.environment === 'test' && typeof FastBoot === 'undefined') {
        if (false
        /* DEBUG */
        ) {
          if (_require.default.has('@ember/test-helpers/dom/get-root-element')) {
            try {
              return (0, _require.default)('@ember/test-helpers/dom/get-root-element').default().id;
            } catch (ex) {// no op
            }
          }

          let rootView = document.querySelector('#ember-testing > .ember-view');

          if (rootView) {
            return rootView.id;
          }

          return '';
        }
      }

      id = config['ember-basic-dropdown'] && config['ember-basic-dropdown'].destination || 'ember-basic-dropdown-wormhole'; // if (DEBUG && typeof FastBoot === 'undefined' && !this.renderInPlace) {
      //   assert(`You're trying to attach the content of a dropdown to an node with ID ${id}, but there is no node with that ID in the document. This can happen when your Ember app is not in control of the index.html page. Check https://ember-power-select.com/docs/troubleshooting for more information`, document.getElementById(id));
      // }

      return id;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hPosition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "vPosition", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "top", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "left", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "right", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "width", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "otherStyles", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.initiallyOpened || false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "renderInPlace", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.renderInPlace !== undefined ? this.args.renderInPlace : false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "open", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reposition", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "reposition"), _class.prototype)), _class));
  _exports.default = BasicDropdown;
});