define("ember-bootstrap/components/bs-nav", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-nav"], function (_exports, _component, _bsNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Nav = (_dec = (0, _component.classNameBindings)('stacked:nav-stacked'), _dec(_class = class Nav extends _bsNav.default {}) || _class);
  _exports.default = Nav;
});