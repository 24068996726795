define("ember-contextual-table/components/dt-selection-column", ["exports", "ember-contextual-table/templates/dt-selection-column"], function (_exports, _dtSelectionColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dtSelectionColumn.default,
    tagName: 'td',
    classNames: ['contextual-selection-cell'],
    actions: {
      change: function (checked) {
        let actionName = checked ? 'rowSelected' : 'rowDeselected';

        let action = this.get(actionName) || function () {};

        action();
      }
    }
  });

  _exports.default = _default;
});