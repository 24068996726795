define("ember-awesome-macros/array/-utils", ["exports", "ember-macro-helpers/normalize-array-key", "ember-macro-helpers"], function (_exports, _normalizeArrayKey, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeArray = normalizeArray;
  _exports.normalizeArray2 = normalizeArray2;
  _exports.normalizeArray3 = normalizeArray3;
  const sentinelValue = {};

  function normalizeArrayArgs(keys) {
    keys[0] = (0, _normalizeArrayKey.default)(keys[0]);
  }

  function convertArray(array) {
    if (array instanceof Ember.ArrayProxy) {
      return array;
    }

    if (Array.isArray(array)) {
      // we use .slice() here so that plain arrays are
      // not internally mutated to Ember arrays
      return Ember.A(array.slice());
    }

    if (Ember.isArray(array)) {
      // this is required by the current `concat()` implementation because
      // Ember.Array itself does not define `concat()` so it only works
      // for Ember.Array instances that are backed by a real array
      return Ember.A(array.toArray());
    }

    return null;
  }

  function getDefaultValue(func, identityVal) {
    let val = func();
    return val === sentinelValue ? identityVal : val;
  }

  function normalizeArray(_ref, callback) {
    let {
      defaultValue = () => sentinelValue
    } = _ref;
    return function () {
      for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      normalizeArrayArgs(keys);
      return (0, _emberMacroHelpers.lazyComputed)(...keys, function (get, arrayKey) {
        let arrayVal = get(arrayKey);
        let emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        for (var _len2 = arguments.length, args = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
          args[_key2 - 2] = arguments[_key2];
        }

        let values = args.map(get);
        return callback.call(this, emberArrayVal, ...values);
      });
    };
  }

  function normalizeArray2(funcStr) {
    let defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : () => sentinelValue;
    return function () {
      for (var _len3 = arguments.length, keys = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        keys[_key3] = arguments[_key3];
      }

      normalizeArrayArgs(keys);
      return (0, _emberMacroHelpers.lazyComputed)(...keys, function (get, arrayKey) {
        let arrayVal = get(arrayKey);
        let emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(defaultValue, arrayVal);
        }

        let prop = emberArrayVal[funcStr];

        if (typeof prop === 'function') {
          for (var _len4 = arguments.length, args = new Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
            args[_key4 - 2] = arguments[_key4];
          }

          return prop.apply(emberArrayVal, args.map(get));
        }

        return prop;
      });
    };
  }

  function normalizeArray3(_ref2) {
    let {
      firstDefault = () => sentinelValue,
      secondDefault = () => sentinelValue,
      func
    } = _ref2;
    return (0, _emberMacroHelpers.createClassComputed)([false, true], function (array, key) {
      for (var _len5 = arguments.length, args = new Array(_len5 > 2 ? _len5 - 2 : 0), _key5 = 2; _key5 < _len5; _key5++) {
        args[_key5 - 2] = arguments[_key5];
      }

      return (0, _emberMacroHelpers.lazyComputed)((0, _normalizeArrayKey.default)(array, [key]), ...args, function (get, arrayKey) {
        let arrayVal = get(arrayKey);
        let emberArrayVal = convertArray(arrayVal);

        if (emberArrayVal === null) {
          return getDefaultValue(firstDefault, arrayVal);
        }

        if (typeof key !== 'string') {
          return getDefaultValue(secondDefault, arrayVal);
        }

        for (var _len6 = arguments.length, args = new Array(_len6 > 2 ? _len6 - 2 : 0), _key6 = 2; _key6 < _len6; _key6++) {
          args[_key6 - 2] = arguments[_key6];
        }

        let resolvedArgs = [key, ...args.map(get)];

        if (typeof func === 'function') {
          return func(emberArrayVal, ...resolvedArgs);
        }

        return emberArrayVal[func](...resolvedArgs);
      });
    });
  }
});