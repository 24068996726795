define("ember-bootstrap/components/base/bs-dropdown", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-dropdown", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _bsDropdown, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const ESCAPE_KEYCODE = 27; // KeyboardEvent.which value for Escape (Esc) key

  const SPACE_KEYCODE = 32; // KeyboardEvent.which value for space key

  const TAB_KEYCODE = 9; // KeyboardEvent.which value for tab key

  const ARROW_UP_KEYCODE = 38; // KeyboardEvent.which value for up arrow key

  const ARROW_DOWN_KEYCODE = 40; // KeyboardEvent.which value for down arrow key

  const SUPPORTED_KEYCODES = [ESCAPE_KEYCODE, ARROW_DOWN_KEYCODE, ARROW_UP_KEYCODE];
  /**
    Bootstrap style [dropdown menus](http://getbootstrap.com/components/#dropdowns), consisting
    of a toggle element, and the dropdown menu itself.
  
    ### Usage
  
    Use this component together with the yielded contextual components:
    * [Components.DropdownToggle](Components.DropdownToggle.html)
    * [Components.DropdownButton](Components.DropdownButton.html)
    * [Components.DropdownMenu](Components.DropdownMenu.html)
      * [Components.DropdownMenuItem](Components.DropdownMenuItem.html)
      * [Components.DropdownMenuDivider](Components.DropdownMenuDivider.html)
      * [Components.DropdownMenuLinkTo](Components.DropdownMenuLinkTo.html)
  
    Furthermore references to the following actions are yielded:
  
    * `toggleDropdown`
    * `openDropdown`
    * `closeDropdown`
  
    ```hbs
    <BsDropdown as |dd|>
      <dd.toggle>Dropdown <span class="caret"></span></dd.toggle>
      <dd.menu as |ddm|>
        <ddm.item>
          <ddm.linkTo @route="index">Something</ddm.linkTo>
        </ddm.item>
        <ddm.item>
          <ddm.linkTo @route="index">Something different</ddm.linkTo>
        </ddm.item>
      </dd.menu>
    </BsDropdown>
    ```
  
    If you need to use dropdowns in a [nav](Components.Nav.html), use the `bs-nav.dropdown`
    contextual component rather than a standalone dropdown to ensure the correct styling
    regardless of your Bootstrap version.
  
    > Note: the use of angle brackets `<ddm.linkTo>` as shown above is only supported for Ember >= 3.10, as it relies on its
    > Ember's native implementation of the [`LinkComponent`](https://api.emberjs.com/ember/3.12/classes/Ember.Templates.helpers/methods/link-to?anchor=link-to).
    > For older Ember versions please use the legacy syntax with positional arguments:
    > `{{#ddm.link-to "bar" this.model}}Bar{{/ddm.link-to}}`
  
    ### Button dropdowns
  
    To use a button as the dropdown toggle element (see http://getbootstrap.com/components/#btn-dropdowns), use the
    `Components.DropdownButton` component as the toggle:
  
    ```hbs
    <BsDropdown as |dd|>
      <dd.button>Dropdown <span class="caret"></span></dd.button>
      <dd.menu as |ddm|>
        <ddm.item>
          <ddm.linkTo @route="index">Something</ddm.linkTo>
        </ddm.item>
        <ddm.item>
          <ddm.linkTo @route="index">Something different</ddm.linkTo>
        </ddm.item>
      </dd.menu>
    </BsDropdown>
    ```
  
    It has all the functionality of a `Components.Button` with additional dropdown support.
  
    ### Split button dropdowns
  
    To have a regular button with a dropdown button as in http://getbootstrap.com/components/#btn-dropdowns-split, use a
    `Components.Button` component and a `Components.DropdownButton`:
  
    ```hbs
    <BsDropdown as |dd|>
      <BsButton>Dropdown</BsButton>
      <dd.button>Dropdown <span class="caret"></span></dd.button>
      <dd.menu as |ddm|>
        <ddm.item>
          <ddm.linkTo @route="index">Something</ddm.linkTo>
        </ddm.item>
        <ddm.item>
          <ddm.linkTo @route="index">Something different</ddm.linkTo>
        </ddm.item>
      </dd.menu>
    </BsDropdown>
    ```
  
    ### Dropup style
  
    Set the `direction` property to "up" to switch to a "dropup" style:
  
    ```hbs
    <BsDropdown @direction="up" as |dd|>
      ...
    </BsDropdown>
    ```
  
    ### Open, close or toggle the dropdown programmatically
  
    If you wanted to control when the dropdown opens and closes programmatically, the `bs-dropdown` component yields the
    `openDropdown`, `closeDropdown` and `toggleDropdown` actions which you can then pass to your own handlers. For example:
  
    ```hbs
    <BsDropdown @closeOnMenuClick={{false}} as |dd|>
      <BsButton>Dropdown</BsButton>
      <dd.button>Dropdown <span class="caret"></span></dd.button>
      <dd.menu as |ddm|>
        {{#each this.items as |item|}}
          <ddm.item>
            <a href onclick={{action "changeItems" item dd.closeDropdown}}>
              {{item.text}}
            </a>
          </ddm.item>
        {{/each}}
      </dd.menu>
    </BsDropdown>
    ```
  
    Then in your controller or component, optionally close the dropdown:
  
    ```js
    ...
    actions: {
      handleDropdownClicked(item, closeDropdown) {
        if(item.isTheRightOne) {
          this.chosenItems.pushObject(item);
          closeDropdown();
        } else {
          this.set('item', this.getRandomItems());
        }
      },
    }
    ```
  
  
    ### Bootstrap 3/4 Notes
  
    If you need to use dropdowns in a [nav](Components.Nav.html), use the `bs-nav.dropdown`
    contextual component rather than a standalone dropdown to ensure the correct styling
    regardless of your Bootstrap version.
  
    If you use the [dropdown divider](Components.DropdownMenuDivider), you don't have to worry
    about differences in the markup between versions.
  
    Be sure to use the [dropdown menu link-to](Component.DropdownMenuLinkTo), for in-application
    links as dropdown menu items. This is essential for proper styling regardless of Bootstrap
    version and will also provide automatic `active` highlighting on dropdown menu items. If you
    wish to have a dropdown menu item refer to an external link, be sure to apply the `dropdown-item`
    class to the `<a>` tag for Bootstrap 4 compatibility.
  
    The dropdown menu will be positioned using the `popper.js` library, just as the original Bootstrap
    version does. This also allows you to set `renderInPlace=false` on the menu component to render it in a wormhole,
    which you might want to do if you experience clipping issues by an outer `overflow: hidden` element.
  
    @class Dropdown
    @namespace Components
    @extends Ember.Component
    @public
  s*/

  let Dropdown = (_dec = (0, _component.layout)(_bsDropdown.default), _dec2 = (0, _component.classNameBindings)('containerClass'), _dec3 = Ember.computed('toggle.tagName', 'direction'), _dec4 = Ember.computed('toggle'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class Dropdown extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "isOpen", _descriptor, this);

      _initializerDefineProperty(this, "closeOnMenuClick", _descriptor2, this);

      _initializerDefineProperty(this, "direction", _descriptor3, this);

      _defineProperty(this, "toggle", null);

      _defineProperty(this, "buttonComponent", 'bs-dropdown/button');

      _defineProperty(this, "toggleComponent", 'bs-dropdown/toggle');

      _defineProperty(this, "menuComponent", 'bs-dropdown/menu');
    }

    /**
     * Indicates the dropdown is being used as a navigation item dropdown.
     *
     * @property inNav
     * @type boolean
     * @default false
     * @private
     */

    /**
     * A computed property to generate the suiting class for the dropdown container, either "dropdown", "dropup" or "btn-group".
     * BS4 only: "dropleft", "dropright"
     *
     * @property containerClass
     * @type string
     * @readonly
     * @private
     */
    get containerClass() {
      if (this.get('toggle.tagName') === 'button' && !this.get('toggle.block')) {
        return this.get('direction') !== 'down' ? `btn-group drop${this.get('direction')}` : 'btn-group';
      } else {
        return `drop${this.get('direction')}`;
      }
    }
    /**
     * @property toggleElement
     * @private
     */


    get toggleElement() {
      return typeof FastBoot === 'undefined' ? this.get('toggle.element') || null : null;
    }
    /**
     * Reference to the child toggle (Toggle or Button)
     *
     * @property toggle
     * @private
     */


    /**
     * The DOM element of the `.dropdown-menu` element
     * @type object
     * @readonly
     * @private
     */
    get menuElement() {
      return document.getElementById(`${this.get('elementId')}__menu`);
    }
    /**
     * Action is called when dropdown is about to be shown
     *
     * @event onShow
     * @param {*} value
     * @public
     */


    onShow(value) {} // eslint-disable-line no-unused-vars

    /**
     * Action is called when dropdown is about to be hidden
     *
     * @event onHide
     * @param {*} value
     * @public
     */


    onHide(value) {} // eslint-disable-line no-unused-vars


    toggleDropdown() {
      if (this.get('isOpen')) {
        this.closeDropdown();
      } else {
        this.openDropdown();
      }
    }

    openDropdown() {
      this.set('isOpen', true);
      this.get('onShow')();
    }

    closeDropdown() {
      this.set('isOpen', false);
      this.get('onHide')();
    }
    /**
     * Handler for click events to close the dropdown
     *
     * @method closeOnClickHandler
     * @param e
     * @protected
     */


    closeHandler(e) {
      let {
        target
      } = e;
      let {
        toggleElement,
        menuElement
      } = this.getProperties('toggleElement', 'menuElement');

      if (!this.get('isDestroyed') && (e.type === 'keyup' && e.which === TAB_KEYCODE && menuElement && !menuElement.contains(target) || e.type === 'click' && toggleElement && !toggleElement.contains(target) && (menuElement && !menuElement.contains(target) || this.get('closeOnMenuClick')))) {
        this.closeDropdown();
      }
    }

    handleKeyEvent(event) {
      // If not input/textarea:
      //  - And not a key in REGEXP_KEYDOWN => not a dropdown command
      // If input/textarea:
      //  - If space key => not a dropdown command
      //  - If key is other than escape
      //    - If key is not up or down => not a dropdown command
      //    - If trigger inside the menu => not a dropdown command
      if (['input', 'textarea'].includes(event.target.tagName.toLowerCase()) ? event.which === SPACE_KEYCODE || event.which !== ESCAPE_KEYCODE && (event.which !== ARROW_DOWN_KEYCODE && event.which !== ARROW_UP_KEYCODE || this.get('menuElement').contains(event.target)) : !SUPPORTED_KEYCODES.includes(event.which)) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (!this.get('isOpen')) {
        this.openDropdown();
        return;
      } else if (event.which === ESCAPE_KEYCODE || event.which === SPACE_KEYCODE) {
        this.closeDropdown();
        this.get('toggleElement').focus();
        return;
      }

      let items = [].slice.call(this.menuElement.querySelectorAll('.dropdown-item:not(.disabled):not(:disabled)'));

      if (items.length === 0) {
        return;
      }

      let index = items.indexOf(event.target);

      if (event.which === ARROW_UP_KEYCODE && index > 0) {
        // Up
        index--;
      }

      if (event.which === ARROW_DOWN_KEYCODE && index < items.length - 1) {
        // Down
        index++;
      }

      if (index < 0) {
        index = 0;
      }

      items[index].focus();
    }
    /**
     * @property buttonComponent
     * @type {String}
     * @private
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isOpen", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "closeOnMenuClick", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "direction", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'down';
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "containerClass", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "containerClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "toggleDropdown", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleDropdown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "openDropdown", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "openDropdown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeDropdown", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "closeDropdown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "closeHandler", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "closeHandler"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyEvent", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyEvent"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = Dropdown;
});