define("ember-contextual-table/templates/data-filterer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bzi7LORh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"data\",\"onfilterfieldupdated\"],[[24,[\"filteredData\"]],[28,\"action\",[[23,0,[]],\"onfilterfieldupdated\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/data-filterer.hbs"
    }
  });

  _exports.default = _default;
});