define("ember-bootstrap/components/bs-navbar/toggle", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-navbar/toggle"], function (_exports, _component, _toggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let NavbarToggle = (_dec = (0, _component.classNames)('navbar-toggle'), _dec(_class = class NavbarToggle extends _toggle.default {}) || _class);
  _exports.default = NavbarToggle;
});