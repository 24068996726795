define("ember-contextual-table/templates/dt-column-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YYig9OWA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"row\",\"rowIndex\",\"body\"],[[24,[\"row\"]],[24,[\"rowIndex\"]],true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"get\",[[24,[\"row\"]],[24,[\"propertyName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/dt-column-cell.hbs"
    }
  });

  _exports.default = _default;
});