define("ember-bootstrap/components/base/bs-dropdown/menu/divider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   Component for a dropdown menu divider.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenuDivider
   @namespace Components
   @extends Ember.Component
   @public
   */
  class DropdownMenuDivider extends Ember.Component {}

  _exports.default = DropdownMenuDivider;
});