define("ember-bootstrap/components/base/bs-modal/header/title", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/header/title"], function (_exports, _component, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  let ModalHeaderTitle = (
  /**
  
   @class ModalHeaderTitle
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.layout)(_title.default), _dec2 = (0, _component.tagName)('h4'), _dec3 = (0, _component.classNames)('modal-title'), _dec(_class = _dec2(_class = _dec3(_class = class ModalHeaderTitle extends Ember.Component {}) || _class) || _class) || _class);
  _exports.default = ModalHeaderTitle;
});