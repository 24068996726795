define("ember-bootstrap/components/bs-dropdown", ["exports", "ember-bootstrap/components/base/bs-dropdown", "@ember-decorators/component"], function (_exports, _bsDropdown, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let Dropdown = (_dec = (0, _component.classNameBindings)('isOpen:open'), _dec(_class = class Dropdown extends _bsDropdown.default {}) || _class);
  _exports.default = Dropdown;
});