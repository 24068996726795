define("ember-contextual-table/components/empty-component", ["exports", "ember-contextual-table/templates/empty-component"], function (_exports, _emptyComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showComponent: false,
    layout: _emptyComponent.default
  });

  _exports.default = _default;
});