define("ember-bootstrap/templates/components/bs-form/element/label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZD5TpeZQ",
    "block": "{\"symbols\":[\"@labelClass\",\"@formElementId\",\"@label\",\"&default\"],\"statements\":[[4,\"if\",[[25,4]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[0,\"\\n    \"],[14,4],[0,\"\\n    \"],[1,[23,3,[]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"class\",[29,[\"control-label \",[28,\"if\",[[23,0,[\"invisibleLabel\"]],\"sr-only\"],null],\" \",[23,1,[]]]]],[11,\"for\",[23,2,[]]],[8],[1,[23,3,[]],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/label.hbs"
    }
  });

  _exports.default = _default;
});