define("ember-bootstrap/components/base/bs-form/element/layout/inline/checkbox", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/layout/inline", "ember-bootstrap/templates/components/bs-form/element/layout/vertical/checkbox"], function (_exports, _component, _inline, _checkbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let FormElementLayoutInlineCheckbox = (
  /**
  
   @class FormElementLayoutInlineCheckbox
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  _dec = (0, _component.layout)(_checkbox.default), _dec(_class = class FormElementLayoutInlineCheckbox extends _inline.default {}) || _class);
  _exports.default = FormElementLayoutInlineCheckbox;
});