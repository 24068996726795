define("ember-bootstrap/components/bs-tab/pane", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-tab/pane"], function (_exports, _component, _pane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let TabPane = (_dec = (0, _component.classNameBindings)('showContent:in'), _dec(_class = class TabPane extends _pane.default {}) || _class);
  _exports.default = TabPane;
});