define("ember-bootstrap/components/base/bs-form/element/control/input", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/control"], function (_exports, _component, _control) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.baseAttributes = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const allowedTypes = new Map();

  function canUseType(type) {
    if (typeof document !== 'object' || typeof document.createElement !== 'function') {
      // consider all types as supported if running in an
      // environment that doesn't support DOM
      return true;
    }

    if (!allowedTypes.has(type)) {
      try {
        let inputElement = document.createElement('input');
        inputElement.type = type;
        allowedTypes.set(type, true);
      } catch (error) {
        allowedTypes.set(type, false);
      }
    }

    return allowedTypes.get(type);
  }

  const baseAttributes = ['name', 'autofocus', 'disabled', 'readonly', 'required', 'tabindex', 'form', 'title', 'ariaDescribedBy:aria-describedby'];
  /**
  
   @class FormElementControlInput
   @namespace Components
   @extends Components.FormElementControl
   @private
   */

  _exports.baseAttributes = baseAttributes;
  let FormElementControlInput = (_dec = (0, _component.tagName)('input'), _dec2 = (0, _component.attributeBindings)(...baseAttributes, 'value', 'type', 'placeholder', 'controlSize:size', 'minlength', 'maxlength', 'min', 'max', 'pattern', 'accept', 'autocomplete', 'autocapitalize', 'autocorrect', 'autosave', 'inputmode', 'multiple', 'step', 'spellcheck'), _dec3 = (0, _component.classNames)('form-control'), _dec4 = Ember.computed, _dec(_class = _dec2(_class = _dec3(_class = (_class2 = class FormElementControlInput extends _control.default {
    /**
     * @property type
     * @type {String}
     * @public
     */
    get type() {
      return 'text';
    }

    set type(value) {
      // fallback to 'text' if value is empty
      if (Ember.isEmpty(value)) {
        return 'text';
      } // IE 11 throws if setting an unsupported type via DOM.
      // We guard against that behaviour by testing if user
      // agent throws on setting the provided type.
      // This is inspired by input helper shipped with Ember.js:
      // https://github.com/emberjs/ember.js/blob/30137796af42c63b28ead127cba0e43e45a773c1/packages/%40ember/-internals/glimmer/lib/components/text_field.ts#L93-L115


      if (!canUseType(value)) {
        return 'text';
      }

      return value;
    }

    change(event) {
      this.get('onChange')(event.target.value);
    }

    input(event) {
      this.get('onChange')(event.target.value);
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "type", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "type"), _class2.prototype)), _class2)) || _class) || _class) || _class);
  _exports.default = FormElementControlInput;
});