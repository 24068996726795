define("ember-awesome-macros/xnor", ["exports", "ember-awesome-macros/not", "ember-awesome-macros/xor"], function (_exports, _not, _xor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return (0, _not.default)((0, _xor.default)(...arguments));
  }
});