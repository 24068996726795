define("ember-contextual-table/components/data-table", ["exports", "ember-contextual-table/templates/data-table"], function (_exports, _dataTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dataTable.default,
    tagName: 'table',
    showHeader: true,
    showFooter: false,
    singleSelection: Ember.computed.equal('selectionMode', 'single'),
    notSelectedRows: Ember.computed.setDiff('data', 'selectedRows'),
    isAllSelected: Ember.computed.empty('notSelectedRows'),
    selectionChanged: function () {},
    classNames: ['contextual-data-table'],
    rowIdPrefixWithTableElementId: Ember.computed('elementId', 'rowIdPrefix', function () {
      let elementId = this.get('elementId');

      if (Ember.isBlank(elementId)) {
        elementId = Ember.guidFor(this);
      }

      let rowIdPrefix = this.get('rowIdPrefix');

      if (Ember.isBlank(rowIdPrefix)) {
        rowIdPrefix = '-table-row-id';
      }

      return elementId + rowIdPrefix;
    }),
    wrappedData: Ember.computed.map('data', function (item) {
      return {
        row: item,
        isSelected: false
      };
    }),
    selectedRows: Ember.computed('wrappedData.@each.isSelected', {
      get() {
        return this.get('wrappedData').filter(item => item.isSelected).map(item => item.row);
      },

      set(key, value) {
        let arr = Ember.A();

        if (Ember.isArray(value)) {
          arr.pushObjects(value);
        }

        this.get('wrappedData').forEach(item => Ember.set(item, 'isSelected', false));
        arr.forEach(item => {
          let found = this.get('wrappedData').find(element => element.row === item);

          if (found) {
            Ember.set(found, 'isSelected', true);
          }
        });
        return arr;
      }

    }),

    selectedFunction(row) {
      if (this.get('selectionMode') === 'single') {
        this.get('wrappedData').forEach(item => Ember.set(item, 'isSelected', false));
      }

      Ember.set(row, 'isSelected', true);
      this.get('selectionChanged')(this.get('selectedRows'));
    },

    deselectedFunction(row) {
      Ember.set(row, 'isSelected', false);
      this.get('selectionChanged')(this.get('selectedRows'));
    },

    actions: {
      selected: function (row) {
        this.selectedFunction(row);
      },
      deselected: function (row) {
        this.deselectedFunction(row);
      },
      selectAll: function () {
        if (this.get('selectionMode') === 'single') {
          return;
        }

        this.get('wrappedData').forEach(item => Ember.set(item, 'isSelected', true));
        this.get('selectionChanged')(this.get('selectedRows'));
      },
      deselectAll: function () {
        this.get('wrappedData').forEach(item => Ember.set(item, 'isSelected', false));
        this.get('selectionChanged')(this.get('selectedRows'));
      },

      handleRowDoubleClick(row) {
        if (this.get('onRowDoubleClick')) {
          this.get('onRowDoubleClick')(row.row);
        } else {
          if (row.isSelected) {
            this.deselectedFunction(row);
          } else {
            this.selectedFunction(row);
          }
        }
      },

      handleRowSingleClick(row) {
        if (this.get('onRowSingleClick')) {
          this.get('onRowSingleClick')(row.row);
        }
      }

    }
  });

  _exports.default = _default;
});