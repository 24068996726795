define("ember-awesome-macros/array/is-every", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.normalizeArray3)({
    firstDefault: () => undefined,
    secondDefault: () => undefined,
    func: 'isEvery'
  });

  _exports.default = _default;
});