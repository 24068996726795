define('ember-leaflet-contextmenu/components/leaflet-contextmenu-item', ['exports', 'ember-leaflet/components/base-layer', 'ember-leaflet-contextmenu/components/leaflet-contextmenu'], function (exports, _baseLayer, _leafletContextmenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const assert = Ember.assert,
        assign = Ember.assign,
        observer = Ember.observer;
  exports.default = _baseLayer.default.extend({
    addToContainer() {
      let parentComponent = this.get('parentComponent');
      let options = assign({ callback: this.get('action') }, this.get('options'));

      assert('Must be wrapped in {{leaflet-contextmenu}}', parentComponent instanceof _leafletContextmenu.default);

      this._element = this._layer = parentComponent._map.contextmenu.addItem(options);
    },

    createLayer() {
      // nothing to do here
    },

    disabledChanged: observer('disabled', function () {
      let parentComponent = this.get('parentComponent');
      let disabled = this.get('disabled');
      parentComponent._map.contextmenu.setDisabled(this._element, disabled);
    }),

    leafletOptions: ['text', 'icon', 'retinaIcon', 'iconCls', 'iconCls', 'disabled'],

    removeFromContainer() {
      this.get('parentComponent')._map.contextmenu.removeItem(this._element);
    }
  });
});