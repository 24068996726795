define("ember-awesome-macros/promise/hash", ["exports", "ember-macro-helpers", "ember-awesome-macros/hash"], function (_exports, _emberMacroHelpers, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    let {
      hashKeys,
      hashValues
    } = (0, _hash.deconstructArgs)(args);
    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, newValues = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        newValues[_key2] = arguments[_key2];
      }

      let newHash = (0, _hash.reduceValues)(hashKeys, newValues);
      return Ember.RSVP.hash(newHash);
    })(...hashValues);
  }
});