define("ember-bootstrap/components/base/bs-nav/link-to", ["exports", "ember-bootstrap/mixins/component-child"], function (_exports, _componentChild) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
  
   Extended `{{link-to}}` component for use within Navs.
  
   @class NavLinkTo
   @namespace Components
   @extends Ember.LinkComponent
   @uses Mixins.ComponentChild
   @public
   */
  class NavLinkTo extends Ember.LinkComponent.extend(_componentChild.default) {}

  _exports.default = NavLinkTo;
});