define("ember-bootstrap/templates/components/bs-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i09CL1AK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"item\",\"link-to\",\"linkTo\",\"dropdown\"],[[28,\"component\",[[23,0,[\"itemComponent\"]]],null],[28,\"component\",[[23,0,[\"linkToComponent\"]]],null],[28,\"component\",[[23,0,[\"linkToComponent\"]]],null],[28,\"component\",[[23,0,[\"dropdownComponent\"]]],[[\"inNav\",\"tagName\"],[true,\"li\"]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-nav.hbs"
    }
  });

  _exports.default = _default;
});