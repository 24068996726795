define("ember-cli-notifications/components/notification-container", ["exports", "ember-cli-notifications/templates/components/notification-container"], function (_exports, _notificationContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _notificationContainer.default,
    position: 'top',
    notifications: Ember.inject.service(),
    classNameBindings: ['computedPosition', ':ember-cli-notifications-notification__container'],
    attributeBindings: ['computedStyle:style', 'position:data-test-notification-container'],
    zindex: '1060',
    computedPosition: Ember.computed('position', function () {
      return `ember-cli-notifications-notification__container--${this.get('position')}`;
    }),
    computedStyle: Ember.computed('zindex', function () {
      return Ember.String.htmlSafe(`z-index: ${this.get('zindex')};`);
    })
  });

  _exports.default = _default;
});