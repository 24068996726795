define("ember-bootstrap/components/bs-dropdown/menu/item", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-dropdown/menu/item"], function (_exports, _component, _item) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let DropdownMenuItem = (_dec = (0, _component.tagName)('li'), _dec(_class = class DropdownMenuItem extends _item.default {}) || _class);
  _exports.default = DropdownMenuItem;
});