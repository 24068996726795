define("ember-awesome-macros/tag", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(strings) {
    for (var _len = arguments.length, values = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      values[_key - 1] = arguments[_key];
    }

    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, values = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      return strings.reduce((total, cur, i) => {
        return total + values[i - 1] + cur;
      });
    })(...values);
  }
});