define("ember-bootstrap/components/base/bs-form/element/help-text", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form/element/help-text"], function (_exports, _component, _helpText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let FormElementHelpText = (
  /**
  
   @class FormElementHelpText
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.layout)(_helpText.default), _dec(_class = class FormElementHelpText extends Ember.Component {}) || _class);
  _exports.default = FormElementHelpText;
});