define("ember-bootstrap/components/bs-form/element/label", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/label"], function (_exports, _component, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let FormElementLabel = (_dec = (0, _component.tagName)(''), _dec(_class = class FormElementLabel extends _label.default {}) || _class);
  _exports.default = FormElementLabel;
});