define("ember-awesome-macros/string/titleize", ["exports", "ember-awesome-macros/string/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.normalizeString)(value => {
    // borrowed from https://github.com/romulomachado/ember-cli-string-helpers/blob/master/addon/utils/titleize.js
    // also waiting on https://github.com/emberjs/rfcs/issues/224
    return value.toLowerCase().replace(/(?:^|\s|-|\/)\S/g, m => m.toUpperCase());
  });

  _exports.default = _default;
});