define("ember-awesome-macros/lt", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberMacroHelpers.curriedComputed)((val1, val2) => {
    return val1 < val2;
  });

  _exports.default = _default;
});