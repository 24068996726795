define("ember-bootstrap/components/bs-navbar", ["exports", "ember-bootstrap/components/base/bs-navbar"], function (_exports, _bsNavbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class Navbar extends _bsNavbar.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_positionPrefix", 'navbar-');

      _defineProperty(this, "_validPositions", ['fixed-top', 'fixed-bottom', 'static-top']);
    }

  }

  _exports.default = Navbar;
});