define("ember-bootstrap/components/base/bs-form/element/layout/inline", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-form/element/layout", "ember-bootstrap/templates/components/bs-form/element/layout/vertical"], function (_exports, _component, _layout, _vertical) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let FormElementLayoutInline = (
  /**
  
   @class FormElementLayoutInline
   @namespace Components
   @extends Components.FormElementLayout
   @private
   */
  _dec = (0, _component.layout)(_vertical.default), _dec(_class = class FormElementLayoutInline extends _layout.default {}) || _class);
  _exports.default = FormElementLayoutInline;
});