define("ember-bootstrap/components/base/bs-modal/body", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-modal/body"], function (_exports, _component, _body) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  let ModalBody = (
  /**
  
   Modal body element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalBody
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component.layout)(_body.default), _dec2 = (0, _component.classNames)('modal-body'), _dec(_class = _dec2(_class = class ModalBody extends Ember.Component {}) || _class) || _class);
  _exports.default = ModalBody;
});