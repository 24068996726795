define("ember-awesome-macros/not-empty", ["exports", "ember-awesome-macros/not", "ember-awesome-macros/is-empty"], function (_exports, _not, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return (0, _not.default)((0, _isEmpty.default)(...arguments));
  }
});