define("ember-bootstrap/components/base/bs-dropdown/menu", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-dropdown/menu", "ember-bootstrap/utils/dom", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _menu, _dom, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DropdownMenu = (
  /**
   Component for the dropdown menu.
  
   See [Components.Dropdown](Components.Dropdown.html) for examples.
  
   @class DropdownMenu
   @namespace Components
   @extends Ember.Component
   @public
   */
  _dec = (0, _component.layout)(_menu.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember.computed('renderInPlace'), _dec4 = Ember.computed, _dec5 = Ember.computed('align'), _dec6 = Ember.computed, _dec7 = Ember.computed('direction', 'align'), _dec8 = Ember._action, _dec9 = Ember.computed('inNav', 'flip'), _dec(_class = _dec2(_class = (_class2 = class DropdownMenu extends Ember.Component {
    constructor() {
      super(...arguments);

      _defineProperty(this, "ariaRole", 'menu');

      _initializerDefineProperty(this, "align", _descriptor, this);

      _initializerDefineProperty(this, "direction", _descriptor2, this);

      _defineProperty(this, "inNav", false);

      _initializerDefineProperty(this, "renderInPlace", _descriptor3, this);

      _defineProperty(this, "_isOpen", false);

      _defineProperty(this, "flip", true);

      _defineProperty(this, "_popperApi", null);

      _defineProperty(this, "itemComponent", 'bs-dropdown/menu/item');

      _defineProperty(this, "linkToComponent", 'bs-dropdown/menu/link-to');

      _defineProperty(this, "dividerComponent", 'bs-dropdown/menu/divider');
    }

    /**
     * @property _renderInPlace
     * @type boolean
     * @private
     */
    get _renderInPlace() {
      return this.get('renderInPlace') || !this.destinationElement;
    }
    /**
     * The wormhole destinationElement
     *
     * @property destinationElement
     * @type object
     * @readonly
     * @private
     */


    get destinationElement() {
      return (0, _dom.getDestinationElement)(this);
    }

    get alignClass() {
      return this.get('align') !== 'left' ? `dropdown-menu-${this.get('align')}` : undefined;
    }

    get isOpen() {
      return false;
    }

    set isOpen(value) {
      // delay removing the menu from DOM to allow (delegated Ember) event to fire for the menu's children
      // Fixes https://github.com/kaliber5/ember-bootstrap/issues/660
      Ember.run.next(() => {
        if (this.get('isDestroying') || this.get('isDestroyed')) {
          return;
        }

        this.set('_isOpen', value);
      });
      return value;
    }

    get popperPlacement() {
      let placement = 'bottom-start';
      let {
        direction,
        align
      } = this.getProperties('direction', 'align');

      if (direction === 'up') {
        placement = 'top-start';

        if (align === 'right') {
          placement = 'top-end';
        }
      } else if (direction === 'left') {
        placement = 'left-start';
      } else if (direction === 'right') {
        placement = 'right-start';
      } else if (align === 'right') {
        placement = 'bottom-end';
      }

      return placement;
    }

    setFocus() {
      // when the dropdown menu is rendered in place, focus can stay on the toggle element
      if (this.get('_renderInPlace')) {
        return;
      }

      let menuElement = document.getElementById(`${this.get('dropdownElementId')}__menu`);

      if (menuElement) {
        menuElement.focus();
      }
    }

    get popperModifiers() {
      return {
        // @todo add offset config
        applyStyle: {
          enabled: !this.get('inNav')
        },
        flip: {
          enabled: this.get('flip')
        }
      };
    }
    /**
     * @property itemComponent
     * @type {String}
     * @private
     */


  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "align", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'left';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "direction", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'down';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "renderInPlace", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "_renderInPlace", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_renderInPlace"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "alignClass", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "alignClass"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isOpen", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperPlacement", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "popperPlacement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setFocus", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "setFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperModifiers", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "popperModifiers"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = DropdownMenu;
});