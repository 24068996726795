define("ember-awesome-macros/promise/-utils", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wrapPromiseProxy = wrapPromiseProxy;

  function wrapPromiseProxy(Proxy) {
    let PromiseProxy = Proxy.extend(Ember.PromiseProxyMixin);
    return (0, _emberMacroHelpers.curriedComputed)(promise => {
      if (promise === undefined) {
        promise = Ember.RSVP.resolve(undefined);
      }

      return PromiseProxy.create({
        promise
      });
    });
  }
});