define("ember-bootstrap/components/base/bs-carousel/slide", ["exports", "@ember-decorators/component", "ember-bootstrap/mixins/component-child", "ember-bootstrap/templates/components/bs-carousel/slide", "ember-bootstrap/utils/cp/overrideable"], function (_exports, _component, _componentChild, _slide, _overrideable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CarouselSlide = (
  /**
    A visible user-defined slide.
  
    See [Components.Carousel](Components.Carousel.html) for examples.
  
    @class CarouselSlide
    @namespace Components
    @extends Ember.Component
    @public
   */
  _dec = (0, _component.classNameBindings)('active'), _dec2 = (0, _component.layout)(_slide.default), _dec3 = (0, _overrideable.default)('isCurrentSlide', 'presentationState', function () {
    return this.get('isCurrentSlide') && this.get('presentationState') === null;
  }), _dec4 = Ember.computed('currentSlide').readOnly(), _dec5 = Ember.computed('followingSlide').readOnly(), _dec(_class = _dec2(_class = (_class2 = class CarouselSlide extends Ember.Component.extend(_componentChild.default) {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "active", _descriptor, this);

      _defineProperty(this, "left", false);

      _defineProperty(this, "next", false);

      _defineProperty(this, "prev", false);

      _defineProperty(this, "right", false);
    }

    /**
     * @private
     * @property isCurrentSlide
     * @type boolean
     */
    get isCurrentSlide() {
      return this.get('currentSlide') === this;
    }
    /**
     * @private
     * @property isFollowingSlide
     * @type boolean
     */


    get isFollowingSlide() {
      return this.get('followingSlide') === this;
    }
    /**
     * Slide is moving to the left.
     *
     * @property left
     * @type boolean
     * @private
     */


    /**
     * Coordinates the execution of a presentation.
     *
     * @method presentationStateObserver
     * @private
     */
    presentationStateObserver() {
      let presentationState = this.get('presentationState');

      if (this.get('isCurrentSlide')) {
        switch (presentationState) {
          case 'didTransition':
            this.currentSlideDidTransition();
            break;

          case 'willTransit':
            this.currentSlideWillTransit();
            break;
        }
      }

      if (this.get('isFollowingSlide')) {
        switch (presentationState) {
          case 'didTransition':
            this.followingSlideDidTransition();
            break;

          case 'willTransit':
            this.followingSlideWillTransit();
            break;
        }
      }
    }

    init() {
      super.init(...arguments);
      Ember.addObserver(this, 'presentationState', null, this.presentationStateObserver, true);
    }
    /**
     * @method currentSlideDidTransition
     * @private
     */


    currentSlideDidTransition() {
      this.set(this.get('directionalClassName'), false);
      this.set('active', false);
    }
    /**
     * @method currentSlideWillTransit
     * @private
     */


    currentSlideWillTransit() {
      this.set('active', true);
      Ember.run.next(this, function () {
        this.set(this.get('directionalClassName'), true);
      });
    }
    /**
     * @method followingSlideDidTransition
     * @private
     */


    followingSlideDidTransition() {
      this.set('active', true);
      this.set(this.get('directionalClassName'), false);
      this.set(this.get('orderClassName'), false);
    }
    /**
     * @method followingSlideWillTransit
     * @private
     */


    followingSlideWillTransit() {
      this.set(this.get('orderClassName'), true);
      Ember.run.next(this, function () {
        this.reflow();
        this.set(this.get('directionalClassName'), true);
      });
    }
    /**
     * Makes things more stable, especially when fast changing.
     */


    reflow() {
      this.element.offsetHeight;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "active", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "isCurrentSlide", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "isCurrentSlide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isFollowingSlide", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "isFollowingSlide"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = CarouselSlide;
});