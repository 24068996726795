define("ember-bootstrap/components/bs-modal/dialog", ["exports", "@ember-decorators/component", "ember-bootstrap/components/base/bs-modal/dialog"], function (_exports, _component, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let ModalDialog = (_dec = (0, _component.classNameBindings)('showModal:in', 'inDom:show'), _dec(_class = class ModalDialog extends _dialog.default {}) || _class);
  _exports.default = ModalDialog;
});