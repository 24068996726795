define("ember-power-select-with-create/components/power-select-multiple-with-create", ["exports", "ember-power-select-with-create/components/power-select-with-create"], function (_exports, _powerSelectWithCreate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PowerSelectMultipleWithCreate = (_dec = Ember._action, (_class = class PowerSelectMultipleWithCreate extends _powerSelectWithCreate.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "powerSelectComponentName", 'power-select-multiple');
    }

    selectOrCreate(selection, select) {
      let suggestion = selection.filter(option => {
        return option.__isSuggestion__;
      })[0];

      if (suggestion) {
        this.args.onCreate(suggestion.__value__, select);
      } else {
        this.args.onChange(selection, select);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectOrCreate", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectOrCreate"), _class.prototype)), _class));
  _exports.default = PowerSelectMultipleWithCreate;
});