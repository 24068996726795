define("ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LbF8nNdd",
    "block": "{\"symbols\":[\"&default\",\"@labelComponent\",\"@errorsComponent\",\"@helpTextComponent\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[23,0,[\"horizontalInputGridClass\"]],\" \",[23,0,[\"horizontalInputOffsetGridClass\"]]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n\"],[4,\"component\",[[23,2,[]]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[1,[28,\"component\",[[23,3,[]]],null],false],[0,\"\\n  \"],[1,[28,\"component\",[[23,4,[]]],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form/element/layout/horizontal/checkbox.hbs"
    }
  });

  _exports.default = _default;
});