define("ember-bootstrap/templates/components/bs-modal-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wA/rpWet",
    "block": "{\"symbols\":[\"@size\",\"@class\",\"&attrs\",\"@title\",\"&default\",\"@submitTitle\"],\"statements\":[[4,\"if\",[[24,[\"inDom\"]]],null,{\"statements\":[[5,\"maybe-in-element\",[],[[\"@destinationElement\",\"@renderInPlace\"],[[22,\"destinationElement\"],[22,\"_renderInPlace\"]]],{\"statements\":[[0,\"    \"],[5,\"bs-modal/dialog\",[[13,3]],[[\"@onClose\",\"@fade\",\"@showModal\",\"@keyboard\",\"@size\",\"@backdropClose\",\"@inDom\",\"@paddingLeft\",\"@paddingRight\",\"@centered\",\"@class\",\"@id\",\"@scrollable\"],[[23,0,[\"close\"]],[23,0,[\"fade\"]],[23,0,[\"showModal\"]],[23,0,[\"keyboard\"]],[23,1,[]],[23,0,[\"backdropClose\"]],[23,0,[\"inDom\"]],[23,0,[\"paddingLeft\"]],[23,0,[\"paddingRight\"]],[28,\"bs-eq\",[[23,0,[\"position\"]],\"center\"],null],[23,2,[]],[23,0,[\"modalId\"]],[23,0,[\"scrollable\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"bs-modal/header\",[],[[\"@title\",\"@closeButton\",\"@onClose\"],[[23,4,[]],[23,0,[\"closeButton\"]],[23,0,[\"close\"]]]]],[0,\"\\n      \"],[5,\"bs-modal/body\",[],[[],[]],{\"statements\":[[0,\"\\n        \"],[14,5,[[28,\"hash\",null,[[\"close\",\"submit\"],[[23,0,[\"close\"]],[23,0,[\"doSubmit\"]]]]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n      \"],[5,\"bs-modal/footer\",[],[[\"@closeTitle\",\"@submitTitle\",\"@submitButtonType\",\"@onClose\",\"@onSubmit\"],[[23,0,[\"closeTitle\"]],[23,6,[]],[23,0,[\"submitButtonType\"]],[23,0,[\"close\"]],[23,0,[\"doSubmit\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"showBackdrop\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"modal-backdrop \",[28,\"if\",[[23,0,[\"fade\"]],\"fade\"],null],\" \",[28,\"if\",[[23,0,[\"showModal\"]],[23,0,[\"showClass\"]]],null]]]],[11,\"id\",[23,0,[\"backdropId\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-modal-simple.hbs"
    }
  });

  _exports.default = _default;
});