define("ember-awesome-macros/array/last", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.normalizeArray)({}, array => {
    return array.objectAt(array.length - 1);
  });

  _exports.default = _default;
});