define("ember-contextual-table/templates/dt-column-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9W5f7oVZ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"if\",[[25,1],[28,\"if\",[[24,[\"defaultHeader\"]],false,true],null],false],null]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"header\"],[true]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"name\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/dt-column-header.hbs"
    }
  });

  _exports.default = _default;
});