define("ember-bootstrap/components/base/bs-navbar/link-to", ["exports", "ember-bootstrap/components/bs-nav/link-to", "ember-bootstrap/utils/default-decorator"], function (_exports, _linkTo, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Extended `{{link-to}}` component for use within Navbars.
   *
   * @class NavbarLinkTo
   * @namespace Components
   * @extends Components.NavLinkTo
   * @public
   */
  let NavbarLinkTo = (_class = class NavbarLinkTo extends _linkTo.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "collapseNavbar", _descriptor, this);
    }

    /**
     * @event onCollapse
     * @private
     */
    onCollapse() {}

    click() {
      if (this.get('collapseNavbar')) {
        this.onCollapse();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "collapseNavbar", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class);
  _exports.default = NavbarLinkTo;
});