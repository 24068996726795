define("ember-bootstrap/components/base/bs-form/element/feedback-icon", ["exports", "@ember-decorators/component", "ember-bootstrap/templates/components/bs-form/element/feedback-icon", "ember-bootstrap/utils/default-decorator"], function (_exports, _component, _feedbackIcon, _defaultDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormElementFeedbackIcon = (
  /**
  
   @class FormElementFeedbackIcon
   @namespace Components
   @extends Ember.Component
   @private
   */
  _dec = (0, _component.layout)(_feedbackIcon.default), _dec2 = (0, _component.tagName)(''), _dec(_class = _dec2(_class = (_class2 = class FormElementFeedbackIcon extends Ember.Component {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "show", _descriptor, this);

      _initializerDefineProperty(this, "iconName", _descriptor2, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "show", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "iconName", [_defaultDecorator.default], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class2)) || _class) || _class);
  _exports.default = FormElementFeedbackIcon;
});