define("ember-contextual-table/templates/row-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XmV6eegJ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"row\",\"rowIndex\",\"isRowSelected\"],[[24,[\"row\"]],[24,[\"rowIndex\"]],[24,[\"isRowSelected\"]]]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-contextual-table/templates/row-component.hbs"
    }
  });

  _exports.default = _default;
});