define("ember-contextual-table/components/dt-column-cell", ["exports", "ember-contextual-table/templates/dt-column-cell"], function (_exports, _dtColumnCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _dtColumnCell.default,
    tagName: 'td',
    classNames: ['contextual-cell']
  });

  _exports.default = _default;
});