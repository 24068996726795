define('ember-drag-drop/components/sortable-objects', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    dragCoordinator: Ember.inject.service(),
    overrideClass: 'sortable-objects',
    classNameBindings: ['overrideClass'],
    enableSort: true,
    useSwap: true,
    inPlace: false,
    sortingScope: 'drag-objects',
    sortableObjectList: Ember.A(),

    init() {
      this._super(...arguments);
      if (this.get('enableSort')) {
        this.get('dragCoordinator').pushSortComponent(this);
      }
    },

    willDestroyElement() {
      if (this.get('enableSort')) {
        this.get('dragCoordinator').removeSortComponent(this);
      }
    },

    dragStart(event) {
      event.stopPropagation();
      if (!this.get('enableSort')) {
        return false;
      }
      this.set('dragCoordinator.sortComponentController', this);
    },

    dragEnter(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },

    dragOver(event) {
      //needed so drop event will fire
      event.stopPropagation();
      return false;
    },

    drop(event) {
      event.stopPropagation();
      if (this.get('enableSort')) {
        this.sendAction('sortEndAction', event);
      }
    }
  });
});